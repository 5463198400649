/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-08",
    versionChannel: "nightly",
    buildDate: "2023-11-08T00:22:09.499Z",
    commitHash: "f334963b47bd89f29aedd19f832a61b3e1341132",
};
